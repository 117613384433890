exports.components = {
  "component---src-components-layout-chapter-tsx": () => import("./../../../src/components/layoutChapter.tsx" /* webpackChunkName: "component---src-components-layout-chapter-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("./../../../src/pages/contact-thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-license-tsx": () => import("./../../../src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-slack-tsx": () => import("./../../../src/pages/slack.tsx" /* webpackChunkName: "component---src-pages-slack-tsx" */)
}

